import axios from "axios";
import { useQuery } from "react-query";
import { GET_POINT_MISSION_DETAIL } from "../Constants/url";
import { getCookie } from "../utils/cookie";

const getPointMissionDetail = async (from: Date, to: Date, userId: number) => {
  const startDate = new Date(from);
  startDate.setHours(9, 0, 0, 0);
  const endDate = new Date(to);
  endDate.setHours(23, 59, 0, 0);
  endDate.setHours(endDate.getHours() + 9);
  const result = await axios.get(GET_POINT_MISSION_DETAIL, {
    params: {
      from: startDate.toISOString(),
      to: endDate.toISOString(),
      userId: userId,
    },
    headers: { Authorization: `Bearer ${getCookie("token")}` },
  });

  return result.data;
};

export const UseGetPointMissionDetail = (
  from: Date,
  to: Date,
  userId: number
) => {
  return useQuery({
    queryKey: ["useGetPointMissionDetail", userId],
    queryFn: () => getPointMissionDetail(from, to, userId == null ? 0 : userId),
  });
};
