import axios from "axios";
import { useQuery } from "react-query";
import { GET_USER_PUBLIC_STAT_BY_ID } from "../Constants/url";
import { getCookie } from "../utils/cookie";

const getUserPublicStat = async (id: number) => {
  const result = await axios.get<any>(GET_USER_PUBLIC_STAT_BY_ID(id), {
    headers: {
      Authorization: `Bearer ${getCookie("token")}`,
    },
  });

  return result.data;
};

export const UseGetUserPublicStat = (id: number) => {
  return useQuery({
    queryKey: ["useGetUserPublicStat", id],
    queryFn: () => getUserPublicStat(id),
  });
};
