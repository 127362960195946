import Login from "./Pages/Login";
import Profile from "./Pages/Profile";
import { Route, Routes } from "react-router-dom";
import PrivateRoute from "./Components/PrivateRoute";
import "./App.css";
import Home from "./Pages/Home";
import List from "./Pages/List";
import PrePare from "./Pages/Setting";
import Layout from "./Components/Layout/layout";
import Payment from "./Pages/Payment";
import Setting from "./Pages/Setting";
import Help from "./Pages/Help";
const App = () => {
  return (
    <>
      <Routes>
        <Route element={<Layout />}>
          <Route element={<PrivateRoute authentication={false} />}>
            <Route path="/" element={<Home />} />
            <Route path="/list/:id" element={<Profile />} />
            <Route path="/list" element={<List />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/setting" element={<Setting />} />
            <Route path="/help" element={<Help />} />
          </Route>
        </Route>

        <Route path="/login" element={<Login />} />
      </Routes>

      {/* <Login /> */}
    </>
  );
};

export default App;
