import { IconType } from "react-icons";

type sidebarComponentData = {
  NewIcon: IconType;
  name: string;
  highlight: boolean;
  onclick: (event: any) => void;
};
const SideBarComponents = ({
  NewIcon,
  name,
  highlight,
  onclick,
}: sidebarComponentData) => {
  const divStyle = highlight
    ? {
        width: "100%",
        backgroundColor: "#DAFFB6",
        padding: "15px 10px 15px 10px",
        display: "flex",
        alignContent: "center",
        margin: "0px 10px 0px 10px",
        borderRadius: 10,
      }
    : {
        width: "100%",
        display: "flex",
        padding: "15px 10px 15px 10px",
        alignContent: "center",
        margin: "0px 10px 0px 10px",
      };

  const iconStyle = highlight
    ? {
        color: "#00C934",
        zIndex: "1",
      }
    : {
        color: "grey",
        zIndex: "1",
      };

  const textStyle = highlight
    ? { fontWeight: "bold", fontSize: 15, color: "#00C934", zIndex: "1" }
    : {
        fontSize: 15,
        zIndex: "1",
      };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
      }}
    >
      <div style={divStyle} onClick={onclick} className={name}>
        <NewIcon size={23} style={iconStyle} />
        <div style={textStyle}>&nbsp;&nbsp;&nbsp;{name}</div>
      </div>
    </div>
  );
};

export default SideBarComponents;
