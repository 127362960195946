import axios from "axios";
import { POST_AUTH_MANAGER_LOCAL } from "../Constants/url";

const postAuthManagerLocal = async (id: string, password: string) => {
  try {
    const result = await axios.post(POST_AUTH_MANAGER_LOCAL, {
      id: id,
      password: password,
    });
    return { isError: false, data: result.data };
  } catch (error) {
    return { isError: true, errorMessage: "아이디와 비밀번호가 달라요" };
  }
};

export const UsePostAuthManagerLocal = (id: string, password: string) => {
  return postAuthManagerLocal(id, password);
};
