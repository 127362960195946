import styles from "./Style/sumPointTable.module.scss";
import SumTableRow from "./sumPointTableRow";

//SumPointTable로 변경하셈
const SumPointTable = ({ data, startDate, endDate }: any) => {
  return (
    <>
      <div className={styles.FirstRow}>
        <div className={styles.column_5}>순위</div>
        <div className={styles.column_15}>상세정보</div>
        <div className={styles.column_45}>닉네임</div>
        <div className={styles.column_20}>포인트</div>
        <div className={styles.column_15}></div>
      </div>
      {data.map((user: any, index: number) => {
        return (
          <SumTableRow
            key={index.toString() + user.name}
            user={user}
            index={index}
            startDate={startDate}
            endDate={endDate}
          />
        );
      })}
    </>
  );
};

export default SumPointTable;
