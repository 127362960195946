import axios from "axios";
import { useQuery } from "react-query";
import { GET_USER_RANK_PERIOD } from "../Constants/url";
import { getCookie } from "../utils/cookie";

const getUserRankPeriod = async (from: Date, to: Date) => {
  const startDate = new Date(from);
  startDate.setHours(9, 0, 0, 0);
  const endDate = new Date(to);
  endDate.setHours(23, 59, 0, 0);
  endDate.setHours(endDate.getHours() + 9);

  const result = await axios.get<any>(GET_USER_RANK_PERIOD, {
    params: { from: startDate.toISOString(), to: endDate.toISOString() },
    headers: { Authorization: `Bearer ${getCookie("token")}` },
  });
  return result.data;
};

export const UseGetUserRankPeriod = (from: Date, to: Date) => {
  return useQuery({
    queryKey: ["useGetUserRankPeriod"],
    queryFn: () => getUserRankPeriod(from, to),
  });
};
