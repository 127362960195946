import React, { useState } from "react";

import "react-datepicker/dist/react-datepicker.css";
import Layout from "../Components/Layout/layout";
import styles from "../Style/home.module.scss";
import { UseGetUserRankPeriod } from "../Hooks/userRankPeriod";
import PieChart from "../Components/Chart/pieChart";
import BarChart from "../Components/Chart/barChart";
import SuccessChart from "../Components/Chart/successChart";
import DepartMentRankList from "../Components/List/departmentranklist";
import PlusButtonToMakeChart from "../Components/plusbuttontomakeChart";

const Home = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const {
    isLoading,
    data: userRank,
    error,
    refetch,
  } = UseGetUserRankPeriod(startDate, endDate);

  const onSearch = () => {
    refetch();
  };

  if (isLoading) return <div>loading...</div>;
  if (error) return <div>error</div>;
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            height: "50%",
            width: "100%",
            display: "flex",
          }}
        >
          <BarChart />
          <PieChart />
        </div>
        <div
          style={{
            width: "100%",
            height: "50%",
            display: "flex",
          }}
        >
          <SuccessChart />
          <DepartMentRankList />
          <PlusButtonToMakeChart />
        </div>
        {/* <div className={styles.homePeriod}>
            <div
              style={{
                paddingTop: 24,
                paddingBottom: 6,
                fontSize: 20,
                fontFamily: "Noto",
              }}
            >
              기간
            </div>
            <div className={styles.datepicker}>
              <DatePickerComponent
                position={"시작"}
                dateInput={[startDate, setStartDate]}
              />
              <div style={{ marginLeft: 10, marginRight: 10 }}>-</div>

              <DatePickerComponent
                position={"끝"}
                dateInput={[endDate, setEndDate]}
                collectDate={startDate}
              />
              <div style={{ paddingLeft: 30 }}>
                <Button disabled={false} onClick={onSearch} ishover={true}>
                  조회하기
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.homeTable}>
            <div
              style={{
                paddingTop: 32,
                fontSize: 24,
                paddingBottom: 16,
                fontWeight: "bold",
              }}
            >
              누적 포인트 랭킹
            </div>
            <SumPointTable
              data={userRank}
              startDate={startDate}
              endDate={endDate}
            />
            </div>*/}
      </div>
    </>
  );
};

export default Home;
