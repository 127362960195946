import { useState } from "react";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import "./components.css";
import calendar from "../Assets/calendar.png";
const DATE_FORMAT = "yyyy.MM.dd";
const DATE_FORMAT_CALENDAR = "yyyy년 MM월";

const DatePickerComponent = ({ position, collectDate, dateInput }: any) => {
  // const [selectDate, setSelectDate] = useState(new Date());
  const [month, setMonth] = useState(new Date().getMonth());
  const handleMonthChange = (newDate: Date) => {
    setMonth(newDate.getMonth());
  };

  const ExampleCustomInput = ({ value, onClick }: any) => (
    <button
      style={{
        borderRadius: 8,
        border: "none",
        fontSize: 16,
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}
      onClick={onClick}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {value}
        <img
          alt={"캘린더"}
          style={{ width: 24, height: 24, paddingLeft: 10 }}
          src={calendar}
        />
      </div>
    </button>
  );

  return (
    <DatePicker
      customInput={<ExampleCustomInput />}
      selected={dateInput[0]}
      dateFormat={DATE_FORMAT}
      dateFormatCalendar={DATE_FORMAT_CALENDAR}
      locale={ko}
      minDate={collectDate == null ? null : collectDate}
      onChange={(date: Date) => {
        if (position === "시작") {
          dateInput[1](date);
        } else if (position === "끝") {
          dateInput[1](date);
        }
        // dateInput[1](date);
      }}
      onMonthChange={handleMonthChange}
      dayClassName={(d) =>
        d.getMonth() === month
          ? d.getDate() === dateInput[0].getDate() &&
            d.getMonth() === dateInput[0].getMonth()
            ? "normal-day selected-day"
            : "normal-day"
          : "extraordinary-day"
      }
    />
  );
};

export default DatePickerComponent;
