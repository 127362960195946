import axios from "axios";
import { PUT_POINT_CANCEL } from "../Constants/url";
import { getCookie } from "../utils/cookie";

const putPointCancel = async (pointId: number) => {
  try {
    const result = await axios.put(
      PUT_POINT_CANCEL,
      {
        pointId: pointId,
        rejectMessage: "",
      },
      {
        headers: {
          Authorization: `Bearer ${getCookie("token")}`,
        },
      }
    );
    return result.data;
  } catch (error) {
    throw error;
  }
};

export const UsePutPointCancel = (pointId: number) => {
  return putPointCancel(pointId);
};
