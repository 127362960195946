import styles from "./Style/header.module.scss";

import { useNavigate } from "react-router-dom";
import { removeCookie } from "../utils/cookie";

const Header = ({ isLogin }: { isLogin: boolean }) => {
  const navigate = useNavigate();

  const onLogOut = () => {
    removeCookie("token");
    navigate("/Login");
  };

  return (
    <div style={{ width: "100%" }}>
      <header className={styles.header}>
        <div className={styles.contents}>
          {isLogin ? (
            <button className={styles.button} onClick={onLogOut}>
              로그아웃
            </button>
          ) : (
            <button className={styles.button}>로그인</button>
          )}
        </div>
      </header>
    </div>
    // <div style={{ height: "100%", width: "100%" }}>
    //
    // </div>
  );
};

export default Header;
