type DepartMentRankData = {
  rank: number;
  teamName: string;
  reduceCo2: string;
};
const DepartMentRankComponent = ({
  rank,
  teamName,
  reduceCo2,
}: DepartMentRankData) => {
  return (
    <div
      style={{
        fontSize: 18,
        height: "40px",
        display: "flex",
        marginLeft: 40,
        marginRight: 40,
        marginTop: 20,
        marginBottom: 20,
        justifyContent: "space-between",
        borderBottom: "1px solid #dbe5eb",
      }}
    >
      <div style={{ display: "flex" }}>
        <div
          style={{
            color: "#273240",
            fontSize: 18,
            fontWeight: "bold",
          }}
        >
          {rank}등
        </div>
        <div style={{ fontSize: 18 }}> &nbsp; {teamName}</div>
      </div>

      <div style={{ color: "#273240", fontSize: 18, paddingBottom: 20 }}>
        CO2 {reduceCo2}
      </div>
    </div>
  );
};

export default DepartMentRankComponent;
