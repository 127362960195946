import { useState } from "react";

import Button from "./button";
import styles from "./Style/sumPointTable.module.scss";
import SumPointTableDetail from "./sumPointTableRowDetail";
import up from "../Assets/chevron-up.png";
import down from "../Assets/chevron-down.png";
import { useNavigate } from "react-router-dom";

const SumTableRow = ({ user, index, startDate, endDate }: any) => {
  let navigate = useNavigate();

  const onClick = () => {
    navigate(`${user.id}`);
  };

  const onHover = () => {
    if (hover) {
      setHover(!hover);
    } else {
      setHover(!hover);
    }
  };

  const [hover, setHover] = useState(false);
  return (
    <>
      <div className={styles.notFirstRow} onClick={onHover}>
        <div className={styles.column_5}>{index + 1}</div>
        <div className={styles.column_15}>
          {hover ? (
            <img src={up} alt={""} width={24} height={24} />
          ) : (
            <img src={down} alt={""} width={24} height={24} />
          )}
        </div>
        <div className={styles.column_45}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignContent: "space-between",
            }}
          >
            <div>{user.name}</div>
            <div style={{ fontSize: 10, color: "gray", fontWeight: "bold" }}>
              {user.guild}
            </div>
          </div>
        </div>

        <div className={styles.column_20_align_center}>{user.totalGreenGp}</div>
        <div className={styles.column_15}>
          <Button onClick={onClick} disabled={false}>
            자세히
          </Button>
        </div>
      </div>
      {hover && (
        // <SumPointTableDetail
        //   refetch={refetch}
        //   isLoading={isLoading}
        //   detailMission={detailMission}
        // />
        <SumPointTableDetail
          index={index}
          startDate={startDate}
          endDate={endDate}
          user={user}
        />
      )}
    </>
  );
};

export default SumTableRow;
