import React, { FC } from "react";
import { ButtonProps } from "../Store/Type/interfaces";
import styles from "./Style/button.module.scss";
const Button: FC<ButtonProps> = ({ disabled, children, onClick }) => {
  return (
    <button
      className={styles.button}
      style={{
        color: "white",
        background: "#00C934",
        fontSize: 18,
        fontWeight: "bold",
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
