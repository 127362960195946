const PlusButtonToMakeChart = () => {
  return (
    <div
      style={{
        width: "33%",
        height: "450px",
        marginBottom: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        style={{
          padding: "8px  18px 8px 18px",
          backgroundColor: "#F3FFE8",
          color: "#7AE32E",
          fontSize: 18,
          fontWeight: "bold",
          border: "none",
          borderRadius: 5,
        }}
      >
        + 추가하기
      </button>
    </div>
  );
};
export default PlusButtonToMakeChart;
