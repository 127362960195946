interface SumPointByKind {
  text: string;
  point: number;
  count: number;
}
const ProfileDetailPointBox = ({ text, point, count }: SumPointByKind) => {
  return (
    <div
      style={{
        width: 380,
        height: 120,
        border: "1px solid #D9D9D9",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        boxShadow: "0px 3px 6px 0px #DBE3E6",
      }}
    >
      <div style={{ paddingLeft: 30 }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ fontSize: 20 }}>{text}</div>
          {/* <div
            style={{
              marginLeft: 8,
              display: "flex",
              width: 44,
              height: 24,
              backgroundColor: "#F9F9F9",
              borderRadius: 10,
              alignItems: "center",
              justifyContent: "center",
              fontSize: 12,
              color: "#616878",
            }}
          >
            {count}회
          </div> */}
        </div>
        <div style={{ display: "flex", alignItems: "end", marginTop: 10 }}>
          <div style={{ fontSize: 36, fontWeight: "bold" }}>{point}</div>
          <div style={{ fontSize: 15 }}>p</div>
        </div>
      </div>
    </div>
  );
};
export default ProfileDetailPointBox;
