import { id } from "../../Store/Type/interfaces";
import ProfileMissionTab from "./profileMissionTab";

const ProfileMission = ({ userId }: id) => {
  return (
    <div>
      <div
        style={{
          fontSize: 32,
          fontFamily: "Noto",
          fontWeight: "bold",
          borderBottom: "1px solid #D9D9D9",
          paddingBottom: 24,
        }}
      >
        미션
      </div>
      <ProfileMissionTab userId={userId} />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          fontFamily: "Noto",
        }}
      >
        {/* <Button disabled={false} onClick={onSearch} ishover={true}>
            조회하기
          </Button> */}
      </div>
    </div>
  );
};

export default ProfileMission;
