import ModalPortal from "../../utils/modalPortal";
import styles from "./Style/imageModal.module.scss";
const RejectModal = ({ onReject, notReject }: any) => {
  return (
    <ModalPortal>
      <div className={styles.Fakebackground}>
        <div
          style={{
            width: 500,
            height: 200,
            background: "white",
            borderRadius: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "start",
          }}
        >
          <div
            style={{
              width: "100%",
              fontSize: 30,
              fontFamily: "Noto",
              fontWeight: "bold",
              borderBottom: "1px solid #d9d9d9",
              display: "flex",
              alignItems: "start",
              padding: 15,
            }}
          >
            승인 취소
          </div>
          <div
            style={{
              width: "100%",
              fontSize: 20,
              padding: 15,
              display: "flex",
              alignItems: "start",
            }}
          >
            선택하신 사진의 승인 취소됩니다
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
            }}
          >
            <button
              style={{
                borderRadius: 8,
                fontFamily: "Noto",
                width: 80,
                padding: 7,
                fontSize: 15,
                color: "white",
                backgroundColor: "#00C934",
                border: "none",
                margin: 5,
                fontWeight: "bold",
              }}
              onClick={onReject}
            >
              예
            </button>
            <button
              style={{
                borderRadius: 8,
                fontFamily: "Noto",
                width: 80,
                padding: 7,
                fontSize: 15,
                color: "white",
                backgroundColor: "gray",
                border: "none",
                margin: 5,
                fontWeight: "bold",
              }}
              onClick={notReject}
            >
              아니요
            </button>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};
export default RejectModal;
