import moment from "moment";
import calendarImg from "../Assets/calendar.png";
import missionImg from "../Assets/mission-number.png";
import heartImg from "../Assets/heart.png";
import styles from "./Style/profileSumPointBox.module.scss";
interface SumPoint {
  point: number;
  maxMissionStrick: number;
  missionNumber: number;
  giveLike: number;
}
const ProfileSumPointBox = ({
  point,
  maxMissionStrick,
  missionNumber,
  giveLike,
}: SumPoint) => {
  var today = moment(new Date()).format("YYYY.MM.DD").toString();

  return (
    <div>
      {/* <div className={styles.firstRow}>
        <div className={styles.firstRowComponent}>
          <div className={styles.firstRowComponentFirstTextComponent}>
            <img src={calendarImg} alt={"캘린더"} width={24} height={24} />
            <div className={styles.title}>연속 미션 수행</div>
          </div>
          <div className={styles.firstRowComponentSecondTextComponent}>
            <div className={styles.firstRowSmallText}>최대</div>
            <div className={styles.firstRowBigText}>{maxMissionStrick}일</div>
          </div>
        </div>
        <div className={styles.firstRowComponent}>
          <div className={styles.firstRowComponentFirstTextComponent}>
            <img src={missionImg} alt={"mission"} width={24} height={24} />
            <div className={styles.title}>누적 미션 수행</div>
          </div>
          <div className={styles.firstRowComponentSecondTextComponent}>
            <div className={styles.firstRowSmallText}>{today} 기준</div>
            <div className={styles.firstRowBigText}>{missionNumber}개</div>
          </div>
        </div>
        <div className={styles.firstRowComponentNoBorder}>
          <div className={styles.firstRowComponentFirstTextComponent}>
            <img src={heartImg} alt={"하트"} width={24} height={24} />
            <div className={styles.title}>좋아요 누른 횟수</div>
          </div>
          <div className={styles.firstRowComponentSecondTextComponent}>
            <div className={styles.firstRowSmallText}>{today} 기준</div>
            <div className={styles.firstRowBigText}>{giveLike}개</div>
          </div>
        </div>
      </div> */}
      <div className={styles.totalPointBoxDecoration}>
        <div style={{ fontSize: 20 }}>총 포인트</div>
        <div style={{ display: "flex", alignItems: "end", marginTop: 10 }}>
          <div style={{ fontSize: 40, fontWeight: "bold" }}>{point}</div>
          <div style={{ fontSize: 20 }}>p</div>
        </div>
      </div>
    </div>
  );
};
export default ProfileSumPointBox;
