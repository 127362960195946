import { useState } from "react";
import { UsePutPointCancel } from "../../Hooks/pointCancel";
import reject from "../../Assets/x.png";
import RejectModal from "../Modal/rejectModal";

const ProfileMissionRejectButton = ({
  pointId,
  refetch,
}: {
  pointId: number;
  refetch: any;
}) => {
  const [onHover, setOnHover] = useState(false);
  const [onRejectModal, setOnRejectModal] = useState(false);
  const onClick = () => {
    setOnRejectModal(true);
  };
  const onReject = async () => {
    await UsePutPointCancel(pointId);
    refetch();
    setOnRejectModal(false);
  };
  const notReject = () => {
    console.log(pointId);
    setOnRejectModal(false);
  };

  return (
    <>
      <div
        onClick={onClick}
        onMouseEnter={() => setOnHover(true)}
        onMouseLeave={() => setOnHover(false)}
        style={{
          position: "absolute",
          width: 40,
          height: 40,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: onHover ? "#00C934" : "#1E1E2C",
        }}
      >
        <img alt={"거절"} width={24} height={24} src={reject} />
      </div>
      {onRejectModal && (
        <RejectModal onReject={onReject} notReject={notReject} />
      )}
    </>
  );
};

export default ProfileMissionRejectButton;
