import { ReactElement } from "react";
import { Navigate, Outlet, Route } from "react-router-dom";
import IsLoginUtil from "../utils/isLoginUtil";

interface PrivateRouteProps {
  Component?: React.ReactNode;
  authentication: boolean;
}

export default function PrivateRoute({ authentication }: PrivateRouteProps) {
  return IsLoginUtil() ? <Outlet /> : <Navigate to="/login" />;
}
