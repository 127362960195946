import React, { useState } from "react";
import { loginFormat } from "../Store/Type/interfaces";
import styles from "../Style/Login.module.scss";
import logo from "../Assets/logo.png";
import { UsePostAuthManagerLocal } from "../Hooks/authManagerLocal";
import { useNavigate } from "react-router-dom";
import { setCookie } from "../utils/cookie";
import moment from "moment";

const initialValue: loginFormat = {
  id: "",
  password: "",
};
const Login = () => {
  let navigate = useNavigate();
  const [inputValues, setInputValues] = useState(initialValue);
  const [iserror, setIsError] = useState(false);

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name: inputName } = event.target;
    setInputValues({ ...inputValues, [inputName]: value });
  };
  const onclick = async () => {
    const expires = moment().add("1", "h").toDate();
    const data = await UsePostAuthManagerLocal(
      inputValues.id,
      inputValues.password
    );
    if (data.isError) {
      setIsError(true);
    } else {
      setCookie("token", data.data.token, { expires });
      navigate("/");
    }
  };

  const disabled = () => {
    return inputValues.id == null && inputValues.password == null;
  };

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      <div
        style={{
          width: "100%",
          height: "80%",
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <img src={logo} height={40} width={134} style={{ marginTop: 31 }} />
          <div
            style={{
              fontSize: 26,
              fontFamily: "Noto",
              fontWeight: "bold",
              marginTop: 15,
            }}
          >
            관리자 로그인
          </div>
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className={styles.inputTitle}>아이디</div>
          <input
            className={styles.inputField}
            name="id"
            onChange={onChangeInput}
          />
          <div className={styles.inputTitle}>비밀번호</div>
          <input
            className={styles.inputField}
            name="password"
            type="password"
            onChange={onChangeInput}
          />
        </div>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            color: "red",
          }}
        >
          {iserror ? "아이디 비밀번호가 틀렸습니다" : null}
        </div>

        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <button className={styles.loginButton} onClick={onclick}>
            로그인 하기
          </button>
        </div>
      </div>
      {/* <Button onClick={onClick} disabled={disabled()} children={"실행하기"} /> */}
    </div>
  );
};

export default Login;
