const SuccessChart = () => {
  return (
    <div
      style={{
        width: "33%",
        height: "450px",
        marginBottom: "20px",
        borderRight: "1px solid #c8cbd9",
      }}
    >
      <div style={{ fontSize: 18, paddingTop: 30 }}>Success Rate</div>
      <div
        style={{
          width: "100%",
          height: "300px",
        }}
      >
        <div
          style={{
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <div
            style={{
              width: "200px",
              height: "200px",
              borderRadius: "50%",
              backgroundColor: "#2FBFDE",
              color: "white",
              marginRight: 20,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ fontSize: 45 }}>92%</div>
            <div style={{ fontSize: 20 }}>성공</div>
          </div>
          <div
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "50%",
              color: "white",
              backgroundColor: "#F99C30",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ fontSize: 32 }}>8%</div>
            <div style={{ fontSize: 15 }}>실패</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessChart;
