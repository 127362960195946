import { useState } from "react";
import { UseGetPointMissionList } from "../../Hooks/pointMissionList";
import { id } from "../../Store/Type/interfaces";
import DatePickerComponent from "../datePicker";
import MissionImage from "../missionImage";
import styles from "./Style/profileMissionTab.module.scss";

const ProfileMissionTab = ({ userId }: id) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const menuArr = [
    { name: "오늘의 그린 미션", kind: "day" },
    { name: "이번주 그린 미션", kind: "week" },
  ];

  const selectMenuHandler = (index: number) => {
    setCurrentTab(index);
  };
  const {
    isLoading,
    data: userMission,
    error,
    refetch,
  } = UseGetPointMissionList(
    userId,
    startDate,
    endDate,
    menuArr[currentTab].kind
  );

  if (isLoading) <div>...loading</div>;
  if (error) <div>...loading</div>;

  //오류 뜨면 접근 금지하게 막음
  if (userMission?.isError === true)
    return (
      <div
        style={{
          fontSize: 40,
          height: 100,
          width: 1200,
          fontWeight: "bold",
          color: "red",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {userMission.data.message}
      </div>
    );

  if (userMission !== undefined) {
    userMission.data.sort((firstMission: any, secondMission: any) => {
      if (firstMission.count < secondMission.count) return 1;
      if (firstMission.count > secondMission.count) return -1;
      return 0;
    });
  }

  return (
    <>
      <div>
        <ul className={styles.tabMenu}>
          {menuArr.map((el, index) => (
            <li
              className={
                index === currentTab ? styles.subMenuOnFocus : styles.subMenu
              }
              onClick={() => selectMenuHandler(index)}
            >
              {el.name}
            </li>
          ))}
        </ul>
        {/* <div>
          <p>{menuArr[currentTab].kind}</p>
          
        </div> */}
        <div
          style={{
            marginTop: 40,
            marginBottom: 16,
            fontFamily: "Noto",
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          기한
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingBottom: 24,
            borderBottom: "1px solid #d9d9d9",
          }}
        >
          <DatePickerComponent
            position={"시작"}
            dateInput={[startDate, setStartDate]}
          />
          <div style={{ marginLeft: 10, marginRight: 10 }}>-</div>

          <DatePickerComponent
            position={"끝"}
            dateInput={[endDate, setEndDate]}
            collectDate={startDate}
          />
        </div>
        <div style={{ marginTop: 24 }}>
          {!isLoading &&
            userMission!.data.map((data: any, index: number) => (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 16,
                  }}
                >
                  <div
                    style={{ color: "#787F90", fontSize: 16, marginRight: 9 }}
                  >
                    {index + 1}위 |
                  </div>
                  <div
                    style={{
                      color: "#1E1E2C",
                      fontFamily: "Noto",
                      marginRight: 4,
                    }}
                  >
                    {data.missionName}
                  </div>
                  <div
                    style={{ color: "#00BB30", fontSize: 16, fontWeight: 600 }}
                  >
                    {data.count}회
                  </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {data.images.map(
                    (item: any, itemIndex: number) =>
                      itemIndex % 7 === 6 ? (
                        <MissionImage
                          img={item}
                          check={true}
                          pointId={data.pointIds[itemIndex]}
                          refetch={refetch}
                        />
                      ) : (
                        <MissionImage
                          img={item}
                          check={false}
                          pointId={data.pointIds[itemIndex]}
                          refetch={refetch}
                        />
                      )
                    // )
                  )}
                </div>
              </>
            ))}
        </div>
      </div>
    </>
  );
};

export default ProfileMissionTab;
