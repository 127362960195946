import * as React from "react";
import { ResponsiveBar } from "@nivo/bar";

const BarChart = () => {
  const handle = {
    barClick: (data: any) => {
      console.log(data);
    },

    legendClick: (data: any) => {
      console.log(data);
    },
  };

  return (
    <div
      style={{
        width: "60%",
        height: "470px",
        borderBottom: "1px solid #c8cbd9",
        borderRight: "1px solid #c8cbd9",
      }}
    >
      <div style={{ fontSize: 15 }}>Reduce</div>
      <div>
        <div style={{ fontSize: 23, fontWeight: "bold" }}>CO2 2,034,520</div>
        <div style={{ fontSize: 12, color: "grey" }}>(단위:g)</div>
      </div>

      <div style={{ fontSize: 13, marginBottom: 20 }}>
        <div style={{ float: "left", color: "green", fontWeight: "bold" }}>
          &#8593; 68% &nbsp;
        </div>
        <div> vs last week</div>
      </div>
      <div style={{ fontSize: 13, marginBottom: 20, color: "grey" }}>
        11월 둘째 주, 2023
      </div>
      <div style={{ width: "100%", height: "350px" }}>
        <ResponsiveBar
          data={[
            { date: "월", 저번주: 121520, 이번주: 125650 },
            { date: "화", 저번주: 225450, 이번주: 225130 },
            { date: "수", 저번주: 324450, 이번주: 351220 },
            { date: "목", 저번주: 254050, 이번주: 373450 },
            { date: "금", 저번주: 379000 },
            { date: "토", 저번주: 122100 },
            { date: "일", 저번주: 155000 },
          ]}
          keys={["이번주", "저번주"]}
          indexBy="date"
          margin={{ bottom: 100, left: 30, right: 30 }}
          padding={0.5}
          innerPadding={10}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          groupMode={"grouped"}
          colors={["#00C934", "#EBFCDA"]}
          colorBy="id" // 색상을 keys 요소들에 각각 적용
          theme={{
            axis: {
              ticks: {
                text: {
                  fontSize: 12,
                  fill: "grey",
                },
              },
            },
          }}
          /**
           * axis bottom 설정
           */
          enableGridY={true}
          gridYValues={[0]}
          enableLabel={false}
          axisBottom={{
            tickSize: 0, // 값 설명하기 위해 튀어나오는 점 크기
            tickPadding: 5, // tick padding
            //   legendPosition: "middle", // 글씨 위치
            //   legendOffset: 40,
            // 글씨와 chart간 간격
          }}
          borderRadius={3}
          borderColor={{ theme: "grid.line.stroke" }}
          axisLeft={null}
          // labelSkipWidth={36}
          // labelSkipHeight={12}
          onClick={handle.barClick}
          tooltip={(item) => {
            return (
              <div style={{ color: "black", fontSize: 17 }}>
                {/* 스타일 다시 먹이기 */}
                <div
                  style={{
                    backgroundColor: "#00B22E",
                    color: "white",
                    borderRadius: 10,
                    width: 100,
                    height: 50,
                    padding: 20,
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      paddingBottom: 5,
                    }}
                  >
                    {item.id}
                  </div>
                  <div style={{ fontSize: 16 }}>
                    {new Intl.NumberFormat("en-US").format(item.value)}g
                  </div>
                </div>
              </div>
            );
          }}
          legends={[
            {
              dataFrom: "keys", // 보일 데이터 형태
              anchor: "bottom-right", // 위치
              direction: "row", // item 그려지는 방향
              justify: false, // 글씨, 색상간 간격 justify 적용 여부
              translateX: -600, // chart와 X 간격
              translateY: 40, // chart와 Y 간격
              itemsSpacing: 2, // item간 간격
              itemWidth: 100, // item width
              itemHeight: 10, // item height
              itemDirection: "left-to-right", // item 내부에 그려지는 방향
              itemOpacity: 0.85, // item opacity
              symbolSize: 20, // symbol (색상 표기) 크기
              effects: [
                // {
                //   on: "hover",
                //   style: {
                //     itemOpacity: 2,
                //   },
                // },
              ],
              onClick: handle.legendClick, // legend 클릭 이벤트
            },
          ]}
        />
      </div>
    </div>
  );
};

export default BarChart;
