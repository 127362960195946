import React, { useEffect, useState } from "react";

import DatePickerComponent from "../Components/datePicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "../Components/button";
import Layout from "../Components/Layout/layout";
import styles from "../Style/home.module.scss";
import { UseGetUserRankPeriod } from "../Hooks/userRankPeriod";
import SumPointTable from "../Components/sumPointTable";

const List = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const {
    isLoading,
    data: userRank,
    error,
    refetch,
  } = UseGetUserRankPeriod(startDate, endDate);

  const onSearch = () => {
    refetch();
  };

  if (isLoading) return <div>loading...</div>;
  if (error) return <div>error</div>;
  return (
    <div style={{ height: "100%", minHeight: "100vh" }}>
      <div
        style={{
          margin: "0 auto",
          width: "1200px",
          height: "100%",
          alignItems: "center",
        }}
      >
        <div className={styles.homePeriod}>
          <div
            style={{
              paddingTop: 24,
              paddingBottom: 6,
              fontSize: 20,
              fontFamily: "Noto",
            }}
          >
            기간
          </div>
          <div className={styles.datepicker}>
            <DatePickerComponent
              position={"시작"}
              dateInput={[startDate, setStartDate]}
            />
            <div style={{ marginLeft: 10, marginRight: 10 }}>-</div>

            <DatePickerComponent
              position={"끝"}
              dateInput={[endDate, setEndDate]}
              collectDate={startDate}
            />
            <div style={{ paddingLeft: 30 }}>
              <Button disabled={false} onClick={onSearch}>
                조회하기
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.homeTable}>
          <div
            style={{
              paddingTop: 32,
              fontSize: 24,
              paddingBottom: 16,
              fontWeight: "bold",
            }}
          >
            누적 포인트 랭킹
          </div>
          <SumPointTable
            data={userRank}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      </div>
    </div>
  );
};

export default List;
