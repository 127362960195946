import ProfileDetailPointBox from "./profileDetailPointBox";
import ProfileSumPointBox from "./profileSumPointBox";

const ProfilePoint = ({ userStat }: any) => {
  const Sum = userStat.daily + userStat.weekly + userStat.groupGp / 200;
  return (
    <div>
      <div style={{ display: "flex", marginTop: 12, marginBottom: 15 }}>
        <img
          style={{ borderRadius: "50%", marginRight: 24 }}
          src={userStat.image}
          alt={"이미지"}
          width={80}
          height={80}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <div style={{ paddingBottom: 8, fontSize: 24, fontWeight: "bold" }}>
            {userStat.name}
          </div>
          <div style={{ color: "#787F90" }}>{userStat.guild}</div>
        </div>
      </div>
      <div style={{ marginBottom: 30 }}>
        <ProfileSumPointBox
          point={userStat.gp}
          maxMissionStrick={userStat.maxMissionStrick}
          missionNumber={Sum}
          giveLike={userStat.giveLike}
        />
      </div>
      <div style={{ fontSize: 24, fontWeight: "bold", marginBottom: 10 }}>
        미션 테마별로 모은 포인트
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <ProfileDetailPointBox
          text={"오늘의 그린 미션"}
          point={userStat.dailyGp}
          count={userStat.daily}
        />
        <ProfileDetailPointBox
          text={"이번주 그린 미션"}
          point={userStat.weeklyGp}
          count={userStat.weekly}
        />
        <ProfileDetailPointBox
          text={"그룹 미션"}
          point={userStat.groupGp}
          count={userStat.groupGp / 200}
        />
      </div>
    </div>
  );
};
export default ProfilePoint;
