import axios, { AxiosError } from "axios";
import { useQuery } from "react-query";
import { GET_POINT_MISSION_LIST } from "../Constants/url";
import { getCookie } from "../utils/cookie";

const getPointMissionList = async (
  userId: number,
  from: Date,
  to: Date,
  kind: string
) => {
  try {
    const startDate = new Date(from);
    startDate.setHours(9, 0, 0, 0);
    const endDate = new Date(to);
    endDate.setHours(23, 59, 0, 0);
    endDate.setHours(endDate.getHours() + 9);
    const result = await axios.get(GET_POINT_MISSION_LIST, {
      params: {
        userId: userId,
        from: startDate.toISOString(),
        to: endDate.toISOString(),
        kind: kind,
      },
      headers: { Authorization: `Bearer ${getCookie("token")}` },
    });

    return { isError: false, data: result.data };
  } catch (error) {
    const { response } = error as unknown as AxiosError;

    if (response) {
      return { isError: true, status: response.status, data: response.data };
    }

    throw error;
  }
  // return error.error.data;
};

export const UseGetPointMissionList = (
  userId: number,
  from: Date,
  to: Date,
  kind: string
) => {
  return useQuery({
    queryKey: ["useGetPointMissionList", from, to, kind],
    queryFn: () => getPointMissionList(userId, from, to, kind),
  });
};
