export const BASE_URL = `${process.env.REACT_APP_API_URL}`;

const USER = "/user";
const POINT = "/point";
const AUTH = "/auth";

export const GET_USER_RANK_PERIOD = `${BASE_URL}${USER}/rank/period`;
export const GET_POINT_MISSION_DETAIL = `${BASE_URL}${POINT}/mission/detail`;
export const GET_USER_PUBLIC_STAT_BY_ID = (id: number) =>
  `${BASE_URL}${USER}/public/stat/${id}`;
export const GET_POINT_MISSION_LIST = `${BASE_URL}${POINT}/mission/list`;
export const POST_AUTH_MANAGER_LOCAL = `${BASE_URL}${AUTH}/manager/local`;
export const PUT_POINT_CANCEL = `${BASE_URL}${POINT}/cancel`;
