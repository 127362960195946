import { useState } from "react";
import ImageModal from "./Modal/imageModal";
import ProfileMissionRejectButton from "./profile/profileMissionRejectButton";
interface item {
  img: string;
  check: boolean;
  pointId: number;
  refetch: any;
}
const MissionImage = ({ img, check, pointId, refetch }: item) => {
  const [onModal, setOnModal] = useState(false);
  const onClose = () => {
    setOnModal(!onModal);
  };
  return check ? (
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        marginBottom: 16,
      }}
    >
      <ProfileMissionRejectButton pointId={pointId} refetch={refetch} />

      <img
        src={img}
        alt={"사진"}
        style={{
          width: 156,
          height: 200,
        }}
      />
    </div>
  ) : (
    <div
      style={{
        display: "flex",
        justifyContent: "end",
        paddingRight: 18,
        marginBottom: 16,
      }}
    >
      <ProfileMissionRejectButton pointId={pointId} refetch={refetch} />
      <div onClick={() => setOnModal(true)}>
        <img
          src={img}
          alt={"사진"}
          style={{
            width: 156,
            height: 200,
          }}
        />
      </div>
      {onModal && <ImageModal image={img} onClose={onClose} />}
    </div>
  );
};
export default MissionImage;
