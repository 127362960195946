import React from "react";
import { getCookie } from "../../utils/cookie";
import Header from "../header";
import styles from "../Style/layout.module.scss";
import SideBar from "../sidebar";
import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <div className={styles.layout}>
        <SideBar />
        <div style={{ width: "100%", height: "100%", flexDirection: "column" }}>
          <Header isLogin={getCookie("token")} />

          <main style={{ marginLeft: 30, marginTop: 30, marginRight: 30 }}>
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
