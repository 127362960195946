import moment from "moment";
import { UseGetPointMissionDetail } from "../Hooks/pointMissionDetail";
// import { UseGetPointMissionDetail } from "../Hooks/pointMissionDetail";
import styles from "./Style/sumPointTable.module.scss";
type missionCountType = {
  [key: string]: number;
};

interface mission {
  missionName: string;
  missionPeriod: string;
  count: number;
  sumGp: number;
}

const SumPointTableRowDetail = ({ startDate, endDate, user }: any) => {
  let totalDayCount = 0;
  let totalWeekCount = 0;
  let totalGroupCount = 0;
  let dayTemp = 1000000;
  let weekTemp = 1000000;
  let weekRank = 0;
  let dayRank = 0;

  // dd/mm/yyyy format
  let newStartDate = moment(startDate, "YYYY-MM-DD")
    .format("YYYY-MM-DD")
    .toString();

  let newEndDate = moment(endDate, "YYYY-MM-DD")
    .format("YYYY-MM-DD")
    .toString();

  let dayMissionCount: missionCountType = {
    "페트병 라벨 떼기": 0,
    "텀블러 사용": 0,
    "장바구니 사용하기": 0,
    "박스 깨끗하게 분리": 0,
    "배달 용기 세척": 0,
    "우유팩 분리수거": 0,
    "자전거 타기": 0,
  };

  let weekMissionCount: missionCountType = {
    "리필 스테이션": 0,
    플로깅: 0,
    "1.5°C 사진 인증": 0,
    "덕분애 사진 인증": 0,
  };

  let groupMissionCount: missionCountType = {
    "친구와 미션 수행하기": 0,
  };
  // const [dayCount, setDayCount] = useState(dayMissionCount);
  const { isLoading, data: detailMission } = UseGetPointMissionDetail(
    startDate,
    endDate,
    user.id
  );

  if (isLoading) return <div>...loading</div>;

  const dayMission = detailMission.filter(
    (mission: mission) => mission.missionPeriod === "day"
  );
  const weekMission = detailMission.filter(
    (mission: mission) => mission.missionPeriod === "week"
  );
  const groupMision = detailMission.filter(
    (mission: mission) => mission.missionPeriod === "group"
  );

  dayMission.map((mission: mission) => {
    dayMissionCount[mission.missionName] = mission.count;
  });
  weekMission.map((mission: mission) => {
    weekMissionCount[mission.missionName] = mission.count;
  });

  groupMision.map((mission: mission) => {
    groupMissionCount[mission.missionName] = mission.count;
  });

  const totalDayCountFunction = (data: missionCountType) => {
    let sum = 0;
    for (let count of Object.values(data)) {
      sum += count;
    }

    return sum;
  };

  totalDayCount = totalDayCountFunction(dayMissionCount);
  totalWeekCount = totalDayCountFunction(weekMissionCount);
  totalGroupCount = totalDayCountFunction(groupMissionCount);

  var sortableWeek = [];
  var sortableDay = [];
  for (var name in dayMissionCount) {
    sortableDay.push([name, dayMissionCount[name]]);
  }

  for (var name in weekMissionCount) {
    sortableWeek.push([name, weekMissionCount[name]]);
  }
  sortableDay.sort(function (a: any, b: any) {
    return b[1] - a[1];
  });
  sortableWeek.sort(function (a: any, b: any) {
    return b[1] - a[1];
  });

  return (
    <>
      <div className={styles.tableDetail}>
        {/* 첫 번째 줄 */}
        <div className={styles.notFirstRow}>
          <div className={styles.column_5}>기간</div>
          <div className={styles.column_15}>
            <div className={styles.calendar}>{newStartDate}</div>
            <div style={{ paddingLeft: 2, paddingRight: 2 }}>-</div>
            <div className={styles.calendar}>{newEndDate}</div>
          </div>

          <div className={styles.column_45}>
            {/* <div>오늘의 그린 미션 + 이번주 그린 미션 +</div> */}

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: 6 }}>오늘의 그린 미션</div>
                {totalDayCount === 0 ? (
                  <div className={styles.showFailCount}>{totalDayCount}회</div>
                ) : (
                  <div className={styles.showSuccessCount}>
                    {totalDayCount}회
                  </div>
                )}
              </div>
              <div>+</div>
              <div style={{ display: "flex" }}>
                <div style={{ paddingRight: 6 }}>이번주 그린 미션</div>
                {totalWeekCount === 0 ? (
                  <div className={styles.showFailCount}>{totalWeekCount}회</div>
                ) : (
                  <div className={styles.showSuccessCount}>
                    {totalWeekCount}회
                  </div>
                )}
              </div>
              <div style={{ paddingRight: 30 }}>+</div>
            </div>
          </div>
          <div className={styles.column_20}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ paddingRight: 6 }}>주간 미션</div>
              {totalGroupCount === 0 ? (
                <div className={styles.showFailCount}>{totalGroupCount}회</div>
              ) : (
                <div className={styles.showSuccessCount}>
                  {totalGroupCount}회
                </div>
              )}
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>=</div>
          </div>
          <div className={styles.column_15}>
            <div style={{ color: "#00C934", fontSize: 20, fontWeight: "Bold" }}>
              {totalDayCount + totalGroupCount + totalWeekCount}회
            </div>
          </div>
        </div>
        {/* 나머지 */}
        <div className={styles.tableDetailNotFirstRow}>
          <div className={styles.column_5}></div>
          <div className={styles.column_15}></div>
          <div className={styles.column_45_not}>
            {/* 일간 미션 */}
            <div
              style={{
                display: "block",
                width: "100%",
                height: "100%",
                alignItems: "center",
                justifyItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "100%",
                }}
              >
                <div className={styles.missionSliceDiv}>
                  {sortableDay.map((mission: any, index: number) => {
                    if (mission[1] < dayTemp) {
                      dayTemp = mission[1];
                      dayRank = dayRank + 1;
                    }
                    return mission[1] === 0 ? (
                      <div className={styles.widthRowSpaceBetween}>
                        <div style={{ display: "flex" }}>
                          <div style={{ width: 30 }}></div>
                          <div style={{ color: "#787F90" }}>{mission[0]}</div>
                        </div>
                        <div
                          style={{
                            color: "#787F90",
                            width: 50,
                            textAlign: "center",
                          }}
                        >
                          -
                        </div>
                      </div>
                    ) : (
                      // </div>
                      <div className={styles.widthRowSpaceBetween}>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              width: 30,
                              color: "#787F90",
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            {dayRank}위
                          </div>
                          <div>{mission[0]}</div>
                        </div>
                        {index === 0 ? (
                          <div
                            style={{
                              width: 50,
                              textAlign: "center",
                              color: "#00BB30",
                            }}
                          >
                            {mission[1]}회
                          </div>
                        ) : (
                          <div
                            style={{
                              width: 50,
                              textAlign: "center",
                            }}
                          >
                            {mission[1]}회
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
                <div style={{ width: "15%" }}></div>
                {/* 주간 미션 */}
                <div className={styles.missionSliceDiv}>
                  {sortableWeek.map((mission: any, index: number) => {
                    if (mission[1] < weekTemp) {
                      weekTemp = mission[1];
                      weekRank = weekRank + 1;
                    }
                    return mission[1] === 0 ? (
                      <div className={styles.widthRowSpaceBetween}>
                        <div style={{ display: "flex" }}>
                          <div style={{ width: 30 }}></div>
                          <div style={{ color: "#787F90" }}>{mission[0]}</div>
                        </div>
                        <div
                          style={{
                            color: "#787F90",
                            width: 50,
                            textAlign: "center",
                          }}
                        >
                          -
                        </div>
                      </div>
                    ) : (
                      //   </div>
                      <div className={styles.widthRowSpaceBetween}>
                        <div style={{ display: "flex" }}>
                          <div
                            style={{
                              width: 30,
                              color: "#787F90",
                              fontSize: 16,
                              fontWeight: "bold",
                            }}
                          >
                            {weekRank}위
                          </div>
                          <div>{mission[0]}</div>
                        </div>
                        <div>
                          {index === 0 ? (
                            <div
                              style={{
                                width: 50,
                                textAlign: "center",
                                color: "#00BB30",
                              }}
                            >
                              {mission[1]}회
                            </div>
                          ) : (
                            <div
                              style={{
                                width: 50,
                                textAlign: "center",
                              }}
                            >
                              {mission[1]}회
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className={styles.column_20}>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  height: "11%",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                친구와 같이 수행하기
              </div>
              {totalGroupCount === 0 ? (
                <div
                  style={{
                    display: "flex",
                    height: "11%",
                    paddingRight: 20,
                    width: 50,
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#787F90",
                    alignItems: "center",
                  }}
                >
                  실패
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    height: "11%",
                    paddingRight: 10,
                    width: 50,
                    textAlign: "center",
                    alignItems: "center",
                    fontWeight: "bold",
                    color: "#00BB30",
                  }}
                >
                  성공
                </div>
              )}
            </div>
          </div>

          <div className={styles.column_15}></div>
        </div>
      </div>
    </>
  );
};
export default SumPointTableRowDetail;
