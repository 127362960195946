import { useState } from "react";
import DepartMentRankComponent from "./departmentComponent";

const DepartMentRankList = () => {
  let [data, userData] = useState([
    ["디자인 1팀", "25,200"],
    ["기획 3팀", "20,200"],
    ["기획 4팀", "19,800"],
    ["세일즈 1팀", "17,700"],
    ["개발 1팀", "12,500"],
  ]);
  return (
    <div
      style={{
        width: "33%",
        height: "450px",
        marginBottom: "20px",
        borderRight: "1px solid #c8cbd9",
      }}
    >
      <div
        style={{
          fontSize: 18,
          paddingTop: 30,
          paddingLeft: 40,
          paddingBottom: 30,
        }}
      >
        DepartMent Rank
      </div>
      {data.map((rank, index) => (
        <DepartMentRankComponent
          rank={index + 1}
          teamName={rank[0]}
          reduceCo2={rank[1]}
        />
      ))}
      {/* <DepartMentRankComponent teamName={"디자인 1팀"} reduceCo2="25,200" />
      <DepartMentRankComponent teamName={"기획 3팀"} reduceCo2="20,200" />
      <DepartMentRankComponent teamName={"기획 4팀"} reduceCo2="19,800" />
      <DepartMentRankComponent teamName={"세일즈 1팀"} reduceCo2="17,700" />
      <DepartMentRankComponent teamName={"개발 1팀"} reduceCo2="12,500" /> */}
    </div>
  );
};

export default DepartMentRankList;
