import { useParams } from "react-router-dom";
import Layout from "../Components/Layout/layout";
import ProfileMission from "../Components/profile/profileMission";
import ProfilePoint from "../Components/profile/profilePoint";
import { UseGetUserPublicStat } from "../Hooks/userPublicStat";

const Profile = () => {
  const { id } = useParams();
  const { isLoading, data: userStat, error } = UseGetUserPublicStat(Number(id));

  if (isLoading)
    return <div style={{ width: "100%", height: "100vh" }}>...loading</div>;
  if (error)
    return <div style={{ width: "100%", height: "100vh" }}>...loading</div>;

  return (
    <>
      <div
        style={{
          margin: "0 auto",
          width: "1200px",
          height: "100%",
          alignItems: "center",
          marginBottom: 300,
        }}
      >
        <ProfilePoint userStat={userStat} />
        <div style={{ marginTop: 64 }}>
          <ProfileMission userId={Number(id)} />
        </div>
      </div>
    </>
  );
};

export default Profile;
