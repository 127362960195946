import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../Assets/logo.png";
import styles from "../Style/sidebar.module.scss";
import { useEffect, useState } from "react";
import SideBarComponents from "./sidebarcomponents";
import { MdAnalytics } from "react-icons/md";
import { MdSettings } from "react-icons/md";
import { FaRankingStar } from "react-icons/fa6";
import { MdPayment } from "react-icons/md";
import { MdHelp } from "react-icons/md";
const nav = {
  DashBoard: "/",
  Ranking: "/list",
  Settings: "/setting",
  Payment: "/payment",
  Help: "/help",
};
type navKey = "DashBoard" | "Ranking" | "Settings" | "Payment" | "Help";
type sideBarState = {
  [key in navKey]: boolean;
};
const SideBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const onclick = () => {
    navigate("/");
    setActiveState("DashBoard");
  };

  const getKeyByValue = (obj: any, value: string) => {
    return Object.keys(obj).find((key) => obj[key] === value);
  };
  const [sideBarClick, setSideBarClick] = useState<sideBarState>({
    DashBoard: true,
    Ranking: false,
    Settings: false,
    Payment: false,
    Help: false,
  });

  useEffect(() => {
    console.log(location);
    const key = getKeyByValue(nav, location.pathname);
    if (key !== undefined) {
      setActiveState(key as navKey);
    }
  }, []);

  const setActiveState = (key: navKey) => {
    // 모든 상태를 false로 설정
    const resetStates = Object.keys(sideBarClick).reduce((acc: any, key) => {
      acc[key] = false;
      return acc;
    }, {});

    setSideBarClick({ ...resetStates, [key]: true });
  };

  const onClick = (e: React.MouseEvent) => {
    const key: navKey = e.currentTarget.className as navKey;
    setActiveState(key);
    navigate(nav[key]);
  };
  return (
    <div>
      <div
        style={{
          width: 240,
          height: "100%",
          backgroundColor: "rgba(218, 255, 182, 0.50)",
        }}
      >
        <div
          style={{
            height: 64,
            width: "100%",
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
            borderBottom: "1px solid #D8DEE4",
          }}
        >
          <img
            style={{ width: "100px", height: "30px" }}
            alt="logo"
            src={logo}
            onClick={onclick}
          />
        </div>
        <div style={{ flexDirection: "column" }}>
          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              paddingTop: 40,
              paddingLeft: 20,
            }}
          >
            <div style={{ marginBottom: 20 }}>MENU</div>
          </div>
          <SideBarComponents
            NewIcon={MdAnalytics}
            name={"DashBoard"}
            onclick={onClick}
            highlight={sideBarClick["DashBoard"]}
          />
          <SideBarComponents
            NewIcon={FaRankingStar}
            name={"Ranking"}
            onclick={onClick}
            highlight={sideBarClick["Ranking"]}
          />

          <div
            style={{
              fontSize: 16,
              fontWeight: "bold",
              paddingTop: 40,
              paddingLeft: 20,
            }}
          >
            <div style={{ marginBottom: 20 }}>OTHERS</div>
          </div>
          <SideBarComponents
            NewIcon={MdSettings}
            name={"Settings"}
            onclick={onClick}
            highlight={sideBarClick["Settings"]}
          />
          <SideBarComponents
            NewIcon={MdPayment}
            name={"Payment"}
            onclick={onClick}
            highlight={sideBarClick["Payment"]}
          />
          <SideBarComponents
            NewIcon={MdHelp}
            name={"Help"}
            onclick={onClick}
            highlight={sideBarClick["Help"]}
          />
        </div>
      </div>
    </div>
  );
};

export default SideBar;
