import ModalPortal from "../../utils/modalPortal";
import styles from "./Style/imageModal.module.scss";
interface Image {
  image: string;
  onClose: () => void;
}

const ImageModal = ({ image, onClose }: Image) => {
  return (
    <ModalPortal>
      <div className={styles.background} onClick={onClose}>
        <img
          src={image}
          alt={"사진"}
          style={{
            width: 468,
            height: 600,
          }}
        />
      </div>
    </ModalPortal>
  );
};

export default ImageModal;
