import { ResponsivePie } from "@nivo/pie";

const PieChart = () => {
  return (
    <div
      style={{
        width: "45%",
        height: "470px",
        borderBottom: "1px solid #c8cbd9",
        paddingLeft: 20,
      }}
    >
      <div style={{ fontSize: 23, fontWeight: "bold", marginTop: 15 }}>
        Mission
      </div>
      <div
        style={{ fontSize: 13, marginBottom: 20, marginTop: 10, color: "grey" }}
      >
        11월 둘째 주, 2023
      </div>
      <div style={{ width: "100%", height: "350px" }}>
        <ResponsivePie
          colors={{ scheme: "yellow_green" }}
          data={[
            {
              id: "투명 페트병 재활용",
              label: "투명 페트병 재활용",
              value: 12,
            },
            { id: "텀블러 사용", label: "텀블러 사용", value: 25 },
            { id: "지하철 타기", label: "지하철 타기", value: 34 },
            { id: "버스 타기", label: "버스 타기", value: 30 },
            { id: "자전거 타기", label: "자전거 타기", value: 25 },
            { id: "기타", label: "기타", value: 12 },
          ]}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          borderColor={{
            from: "color",
            modifiers: [["darker", 0.2]],
          }}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          defs={[]}
          fill={[]}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 10,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 18,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
};

export default PieChart;
